body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main-container {
  display: inline-block;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}

.phone-bezel {
  position: relative;
  display: block;
  margin: auto;
  border-radius: 50px;
  background-color: aquamarine;
  height: 680px;
  width: 340px;
}

.phone-cutout {
  position: absolute;
  margin: 0% 25%;
  border-radius: 0% 0% 5% 5% / 0% 0% 50% 50%;
  height: 3%;
  width: 50%;
  background-color: aquamarine;
  z-index: 1000;
}

.phone-content {
  position: absolute;
  margin: 3% 5%;
  width: 90%;
  height: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.phone-content-top {
  position: absolute;
  margin: 3% 5%;
  width: 90%;
  height: 94%;
  display: flex;
  flex-direction: column;
}

.phone-screen {
  position: absolute;
  border-radius: 40px;
  background-color: azure;
  margin: 5% 5% 5% 5%;
  height: 95%;
  width: 90%;
}

/* mock browser */
.browser-container {
  position: relative;
  display: block;
  margin: 10px auto;
  border-radius: 5px;
  border: 1px solid rgb(175, 175, 175);
  background-color: #ffffff;
  height: 300px;
  width: 400px;
}

.browser-header {
  position: absolute;
  display: flex;
  border-radius: 5px 5px 0px 0px;
  height: 20px;
  width: 100%;
  background-color: #dee0e5;
  z-index: 1000;
}

.dot {
  position: relative;
  width: 8px;
  height: 8px;
  left: 6px;
  top: 6px;
  border-radius: 50%;
  margin: 0px 2px 0px;
}

#red-dot {
  background-color: #db615a;
}

#yellow-dot {
  background-color: #edc041;
}

#green-dot {
  background-color: #7dce50;
}

.browser-content {
  position: absolute;
  margin: 3% 5%;
  height: 94%;
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* mock chat app */
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.messages {
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 3px;
  margin-bottom: 3px;
  display: inline-block;
}

.rec {
  align-items: flex-start;
}

.rec .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.rec .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}

.rec .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: azure;
  border-bottom-right-radius: 10px;
}

.sent {
  align-items: flex-end;
}

.sent .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  position: relative;
}

.sent .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.sent .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: azure;
  border-bottom-left-radius: 10px;
}

.takeaway {
  /* background: linear-gradient(180deg,rgba(255,255,255,0) 10%, #ffdcc4 10%); */
  padding: 10px 10px;
  border-radius: 10px;
  border: 2px solid #a1d900;
}

a {
  color: black;
  font-weight: 550;
  text-decoration: none;
}

.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.hero.is-fullheight {
  min-height: 100vh;
}

p {
  text-align: left;
}

.text-non-selectable {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
}
